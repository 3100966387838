import { Modal as FlowModal } from "flowbite-react"
import CloseIcon from "icons/CloseIcon"
import { motion } from "framer-motion"

export interface BaseModalProps extends React.ComponentProps<typeof FlowModal> {
	children: React.ReactNode
	openModal: boolean
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
	title?: string
	subtitle?: string
	width?: string
	onlyCloseButton?: boolean
	whitoutCloseButton?: boolean
	className?: string
}

const BaseModal = ({
	children,
	openModal,
	setOpenModal,
	title,
	subtitle,
	width,
	onlyCloseButton,
	whitoutCloseButton,
	className,
	...props
}: BaseModalProps) => {
	return (
		<FlowModal
			show={openModal}
			onClose={() => setOpenModal(false)}
			{...props}
			className={`focus-visible:!outline-none ${className} !w-auto `}
			theme={{
				content: {
					inner: "shadow-none flex items-center justify-center",
				}
			}}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.7 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ ease: "easeOut", duration: 0.2 }}
				className="flex items-center justify-center"
			>
				<FlowModal.Body

					className={`rounded-lg bg-[white] p-[24px] ${onlyCloseButton && !whitoutCloseButton ? "relative" : ""
						} ${width} focus-visible:!outline-none `}
				>
					{title && (
						<div className="mb-2 flex items-center justify-between">
							<h3 className="font-semibold text-[22px] font-lato">
								{title}
							</h3>
							{!whitoutCloseButton && (
								<div
									className="absolute top-6 cursor-pointer right-6"
									onClick={() => setOpenModal(false)}
								>
									<CloseIcon size="24px" />
								</div>
							)}
						</div>
					)}

					{onlyCloseButton && !whitoutCloseButton && (
						<div
							className="absolute cursor-pointer top-6 right-6"
							onClick={() => setOpenModal(false)}
						>
							<CloseIcon size="24px" />
						</div>
					)}

					{subtitle && (
						<div className="mb-8">
							<p className="text-cta-1 text-[#606060]">
								{subtitle}
							</p>
						</div>
					)}

					{children}
				</FlowModal.Body>
			</motion.div>
		</FlowModal>
	)
}

const Modal = ({
	openModal,
	setOpenModal,
	children,
	title,
	subtitle,
	width,
	onlyCloseButton,
	className,
	...props
}: BaseModalProps) => {
	return (
		<BaseModal
			openModal={openModal}
			setOpenModal={setOpenModal}
			title={title}
			subtitle={subtitle}
			width={width}
			onlyCloseButton={onlyCloseButton}
			className={className}
			{...props}
		>
			{children}
		</BaseModal>
	)
}

export default Modal
