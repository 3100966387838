import CurrencyIcon from "icons/CurrencyIcon"
import SecurityIcon from "icons/SecurityIcon"
import UserIcon from "icons/UserIcon"
import { ReactNode, useState } from "react"

import Profile from "./subcomponents/Profile"
import PlansAndPayment from "./subcomponents/PlansAndPayment"
import Account from "./subcomponents/Account"
import ImagePrefetch from "components/image-prefetch"
import working from "assets/working.png"

interface TabProps {
	icon: ReactNode
	text: string
	active?: boolean
	onClick?: () => void
}
function Tab({ icon, text, active, onClick }: TabProps) {
	const className = active
		? "pb-[2px] bg-clip-border bg-gradient-to-r from-light-blue-4 to-light-blue-5 cursor-pointer text-light-blue-1"
		: "cursor-pointer text-brand-gray-2 hover:text-dark-blue-2"
	return (
		<div className={className} onClick={onClick}>
			<div className="bg-ice-white pb-2 px-2 ">
				<div className={`flex text-current`}>
					<div className="pr-2">{icon}</div>
					{text}
				</div>
			</div>
		</div>
	)
}

const SettingsPage = () => {
	const [selectedTab, setSelectedTab] = useState("profile")

	return (
		<div className=" pb-6">
			<div className="pb-[32px]">
				<h1 className=" font-semibold text-text-dark-primary font-lato">
					Configurações
				</h1>
			</div>
			<div className="flex pb-6">
				<Tab
					icon={<UserIcon />}
					text="Informações Pessoais"
					active={selectedTab === "profile"}
					onClick={() => setSelectedTab("profile")}
				/>
				<Tab
					icon={<SecurityIcon />}
					text="Conta e Segurança"
					active={selectedTab === "account"}
					onClick={() => setSelectedTab("account")}
				/>

				<Tab
					icon={<CurrencyIcon />}
					text="Planos e Pagamentos"
					active={selectedTab === "plans"}
					onClick={() => setSelectedTab("plans")}
				/>
			</div>

			<div className="bg-brand-white-1 w-auto p-6 rounded-lg">
				{selectedTab === "profile" && <Profile />}
				{selectedTab === "plans" && (
					<PlansAndPayment />
				)}

				{selectedTab === "account" && <Account />}
				<ImagePrefetch imageUrl={working} />
			</div>
		</div>
	)
}

export default SettingsPage
